import React, { useEffect, useRef, useState } from 'react'
import { layouts, useServices, useSettings, useWhiteLabelServiceSettings, constants } from 'cng-web-lib'
import { makeStyles } from '@material-ui/core'
import PartyConfigApiUrls from 'src/apiUrls/PartyConfigApiUrls'
import CustomTopBar from '../CustomCngLayout/CustomTopBar/index.js'
import UpsCustomTopBar from '../CustomCngLayout/UpsCustomTopBar/index.js'
import { FileForUserProvider } from 'src/contexts/FileForUserContext.js'
import { FileForUserGetUserDetails,FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import PropTypes from 'prop-types'
import clsx from 'clsx'

const { filter: { EQUAL } } = constants

const { CngSideBar, NotificationTransactionContext } = layouts

const components = {
  CustomTopBar: CustomTopBar,
  UpsCustomTopBar: UpsCustomTopBar
};

const styling = {
  DefaultSCSS: async function importDefaultSCSS() {
    await import('src/assets/scss/default.scss')
    await import('src/assets/scss/defaultTheme.scss')
  },
  UpsSCSS: async function importUPSSCSS() {
    await import('src/assets/scss/upsTheme.scss')
  },
  RbiSCSS: async function importRBISCSS() {
    await import('src/assets/scss/default.scss')
    await import('src/assets/scss/rbiTheme.scss')
  },
  RafSCSS: async function importRAFSCSS() {
    await import('src/assets/scss/default.scss')
    await import('src/assets/scss/rafTheme.scss')
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapperDesktop: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: (props) => props.topBarId === 'UpsCustomTopBar' ? 90 : 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  navBarPositionTopWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: (props) => (props.topBarId === 'UpsCustomTopBar' ? 90 : 64) + 50,
    [theme.breakpoints.down('md')]: {
      paddingTop: (props) => props.topBarId === 'UpsCustomTopBar' ? 90 : 64,
    }
  },
  wrapperDesktopDrawerMini: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: (props) => props.topBarId === 'UpsCustomTopBar' ? 90 : 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 100
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}))

function CustomCngLayout({
  renderNavBar = (onMobileClose, openMobile, isDesktopSideBarMini) => (
    <CngSideBar
      onMobileClose={onMobileClose}
      openMobile={openMobile}
      isDesktopSideBarMini={isDesktopSideBarMini}
    />
  ),
  children
}) {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const [isDesktopSideBarMini, setDesktopSideBarMini] = useState(false)

  const { settings } = useSettings()
  const isNavBarPositionTop = settings.navBarPosition === 'top'

  const notificationTransactionMenuRef = useRef()

  function closeMobileNav() {
    setMobileNavOpen(false)
  }

  function openMobileNav() {
    setMobileNavOpen(true)
  }

  function toggleSideNav() {
    setDesktopSideBarMini(!isDesktopSideBarMini)
  }

  const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout");
  const [customTopBarId, setCustomTopBarId] = useState('CustomTopBar');
  const classes = useStyles({ topBarId: customTopBarId })

  useEffect(() => {
    if (whiteLabelServiceSettings.length > 0) {
      const topBarId = whiteLabelServiceSettings[0].custom.customTopBarId;
      const customSCSS = whiteLabelServiceSettings[0].custom.customSCSS;

      setCustomTopBarId(topBarId == undefined ? 'CustomTopBar' : topBarId);

      if (customSCSS !== undefined) {
        styling[customSCSS]()
      }
    } else {
      styling.DefaultSCSS()
    }
  }, [])

  const { securedSendRequest,fetchRecords } = useServices();
  const [user, setUser] = useState([]);

  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  // Set UI Preference in session
  useEffect(() => {
    if (user.uiPreference && user.uiPreference.length > 0) {
      sessionStorage.setItem('uiPreference', JSON.stringify(user.uiPreference))
    }

    sessionStorage.setItem('nangUserProfile', JSON.stringify(user))

    fetchRecords.execute(
      PartyConfigApiUrls.GET,
      {
        filters: [
          {
            field: 'partyId',
            operator: EQUAL,
            value: FileForUserGetPartyId()
          }
        ]
      },
      (res) => {
        if(res.content.length>0){
          sessionStorage.setItem('nangPartyConfig', JSON.stringify(res.content))
        }
      });
  }, [user])


  function renderTopBar(customTopBarId, openMobileNav, toggleSideNav) {
    const TopBar = components[customTopBarId];
    return (
      <TopBar onMobileNavOpen={openMobileNav} onToggleDesktopSideBar={toggleSideNav} />
    )
  }

  return (
    <NotificationTransactionContext.Provider value={{ notificationTransactionMenuRef }}>
      <FileForUserProvider>
        <div className={clsx(classes.root, customTopBarId)}>
          {renderTopBar(customTopBarId, openMobileNav, toggleSideNav)}
          {renderNavBar(closeMobileNav, isMobileNavOpen, isDesktopSideBarMini)}
          <div
            className={
              isNavBarPositionTop ? classes.navBarPositionTopWrapper :
                isDesktopSideBarMini ? classes.wrapperDesktopDrawerMini : classes.wrapperDesktop
            }
          >
            <div className={classes.contentContainer}>
              <div className={classes.content} id='mainContent'>{children}</div>
            </div>
          </div>
        </div>
      </FileForUserProvider>
    </NotificationTransactionContext.Provider>
  )
}

CustomCngLayout.propTypes = {
  renderTopBar: PropTypes.func,
  renderNavBar: PropTypes.func,
  children: PropTypes.any
}

export default CustomCngLayout
