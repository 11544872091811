import React, { useState, useContext, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { layouts, useSettings, useTranslation, useWhiteLabelServiceSettings } from 'cng-web-lib'
import MenuIcon from '@material-ui/icons/Menu'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import { AppBar, Box, Hidden, IconButton, SvgIcon, Toolbar, Tooltip, makeStyles, InputLabel } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ThemeLibrary from 'src/theme/ThemeLibrary'
import WhiteLabelLogo from './WhiteLabelLogo'
import useFileForUser from 'src/hooks/useFileForUser'
import FileForUserContext from 'src/contexts/FileForUserContext'
import Namespace from 'src/constants/locale/Namespace'
import HomePageKeys from 'src/constants/locale/key/HomePage'
import Announcement from '../Announcement/index'
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import ProductInfo from './ProductInfo'
import TradeResources from './TradeResources'
import FAQ from './FAQ'

const { Accounts, Notifications, CngSettings } = layouts

const THEMES = { LIGHT: 'LIGHT', ONE_DARK: 'ONE_DARK' }

const defaultLightThemeTopBarBg = 'linear-gradient(270deg, rgba(0,133,125,1) 5%, rgba(0,167,157,1) 25%, rgba(0,189,177,1) 50%, rgba(255,255,255,1) 90%)'
const defualtDarkThemeTopBarBg = 'linear-gradient(270deg, rgb(0 0 0) 5%, rgb(0 38 36) 25%, rgb(1 104 98) 50%, rgba(255,255,255,1) 90%)'

function getCustomTopBarStyling(whiteLabelServiceSettings) {
  let customTopBarStylingJSON = null

  if (whiteLabelServiceSettings && whiteLabelServiceSettings.length > 0) {
    const customTopBarStyling = whiteLabelServiceSettings[0].custom.customTopBarStyling;
    if (customTopBarStyling && customTopBarStyling != undefined) {
      customTopBarStylingJSON = JSON.parse(customTopBarStyling)
    }
  }
  return customTopBarStylingJSON
}

const useStyles = makeStyles((theme) => {
  const customStylingJSON = getCustomTopBarStyling(useWhiteLabelServiceSettings('custom-layout'))

  let lightThemeBg = defaultLightThemeTopBarBg
  let darkThemeBg = defualtDarkThemeTopBarBg
  let color = theme.palette.common.white
  let hamIconColor = theme.palette.common.black

  if (customStylingJSON) {
    if (customStylingJSON.backgroundLight && theme.name === THEMES.LIGHT) {
      lightThemeBg = customStylingJSON.backgroundLight
    }

    if (customStylingJSON.backgroundDark && theme.name === THEMES.ONE_DARK) {
      darkThemeBg = customStylingJSON.backgroundDark
    }

    if (customStylingJSON.color) {
      color = customStylingJSON.color
    }

    if (customStylingJSON.hamIconColor) {
      hamIconColor = customStylingJSON.hamIconColor
    }
  }

  return ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
        backgroundColor: theme.palette.background.othersTopbar,
        color: '#263238'
      }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
        backgroundColor: theme.palette.background.othersTopbar
      }
      : {})
  },
  toolbar: {
    background: theme.palette.mode === 'dark' ? darkThemeBg : lightThemeBg,
    color: color,
    minHeight: 64,
    '& .MuiIconButton-root .MuiIconButton-label': {
      color: color,
    },
    '& .MuiButtonBase-root.btn-loggedin-account': {
      color: color,
      '&.active': {
        borderColor: color,
        '& svg': {
          fill: color
        }
      },
      '& .loggedin-username, .loggedin-userid': {
        color: color,
      },
      '& .loggedin-username': {
        fontSize: theme.typography.body1.fontSize
      },
      '& .loggedin-userid': {
        fontSize: theme.typography.body2.fontSize
      }
    }
  },
  hamburgerMenu: {
    left: -10,
    position: 'relative',
    '& svg': {
      color: hamIconColor
    }
  },
  fileForUserLabel: {
    'font-weight': 'bold',
    color: 'red'
  },
  userDetails: {
    '& .MuiButtonBase-root': {
      '&.btn-loggedin-account.active svg': {
        fill: '#121212'
      },
      '& .MuiAvatar-root': {
        '&.MuiAvatar-colorDefault': {
          backgroundColor: '#FFC400',
          color: '#121212'
        }
      }
    }
  }
  })
})

function CustomTopBar({
  className,
  onMobileNavOpen,
  onToggleDesktopSideBar,
  renderLogo = () => <WhiteLabelLogo />,
  renderSettings,
  shouldHideNotifications,
  ...rest
}) {

  let classes = useStyles()
  const [hamIcon, setHamIcon] = useState(false)
  const { setFileForUserValue } = useContext(FileForUserContext)
  const fileForUserValue = useFileForUser()
  const { getHomePagePath } = useSettings()
  const { translate } = useTranslation(Namespace.HOME_PAGE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")

  //default top bar menu setting
  const [renderAboutUs, setRenderAboutUs] = useState(true)
  const [renderContactUs, setRenderContactUs] = useState(true)
  const [renderProductInfo, setRenderProductInfo] = useState(false)
  const [renderTradeResources, setRenderTradeResources] = useState(false)
  const [renderFAQ, setRenderFAQ] = useState(false)
  const [logoutPath,setLogoutPath] = useState("/")

  useEffect(() => {
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");

    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      let fileForUserDetailsJSON = JSON.parse(fileForUserDetails);
      setFileForUserValue(fileForUserDetailsJSON);
    }

    //determine white label top bar menu
    if (whiteLabelServiceSettings.length > 0) {
      //overwrite default setting
      let wbContactUsPath = whiteLabelServiceSettings[0].custom.contactUs
      if (wbContactUsPath == null || wbContactUsPath == undefined) {
        setRenderContactUs(false)
      }

      //overwrite default setting
      let wbAboutUsPath = whiteLabelServiceSettings[0].custom.aboutUs
      if (wbAboutUsPath == null || wbAboutUsPath == undefined) {
        setRenderAboutUs(false)
      }

      //start - white label menu 
      let wbProductInfoPath = whiteLabelServiceSettings[0].custom.productInfo
      if (wbProductInfoPath != null && wbProductInfoPath != undefined) {
        setRenderProductInfo(true)
      }

      let wbTradeResourcesPath = whiteLabelServiceSettings[0].custom.tradeResources
      if (wbTradeResourcesPath != null && wbTradeResourcesPath != undefined) {
        setRenderTradeResources(true)
      }

      let wbFAQPath = whiteLabelServiceSettings[0].custom.faq
      if (wbFAQPath != null && wbFAQPath != undefined) {
        setRenderFAQ(true)
      }

      let logout = whiteLabelServiceSettings[0].custom.logout
      if (logout != null && logout != undefined) {
        setLogoutPath(logout)
      }
      //end - white label menu
    }
  }, []);

  function makeTranslatedTextsObject() {
    let fileForUser = translate(Namespace.HOME_PAGE, HomePageKeys.FILE_FOR_USER)
    let toggleSidebar = translate(Namespace.HOME_PAGE, HomePageKeys.TOGGLE_SIDEBAR)
    return { fileForUser, toggleSidebar }
  }

  function toggleIcon() {
    !hamIcon ? setHamIcon(true) : setHamIcon(false)
  }

  function getThemeLibrary(whiteLabelServiceSettings) {
    const whiteLabelThemeLibrarySettings = whiteLabelServiceSettings.find(
      (whiteLabelServiceSettings) => {
        return whiteLabelServiceSettings.serviceName === "custom-layout"
      }
    )
    let themeNames = undefined
    let themeConfigurations = undefined
    if (whiteLabelThemeLibrarySettings) {
      if(whiteLabelThemeLibrarySettings.custom.themeNames!=undefined && whiteLabelThemeLibrarySettings.custom.themeConfigurations!=undefined){
        themeNames = JSON.parse(whiteLabelThemeLibrarySettings.custom.themeNames)
        themeConfigurations = JSON.parse(whiteLabelThemeLibrarySettings.custom.themeConfigurations)
      }
    }
    return new ThemeLibrary(themeNames, themeConfigurations)
  }

  function getFileForUser() {
    if (fileForUserValue.fileForUserLoginId != null && fileForUserValue.fileForUserLoginId != undefined) {
      return (
        <Box ml={2} align='center'>
          <InputLabel className={clsx(classes.fileForUserLabel, className)}>
            {translatedTextsObject.fileForUser}: {fileForUserValue.fileForUserLoginId}
          </InputLabel>
        </Box>
      )
    }
  }

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Announcement/>
      <Toolbar className={classes.toolbar}>
        <Hidden mdDown>
          <Tooltip title={translatedTextsObject.toggleSidebar} className={classes.hamburgerMenu}>
            <IconButton
              className={clsx(classes.menuButton, hamIcon ? 'state-expanded' : 'state-collapsed')}
              color='inherit'
              onClick={(event) => {
                onToggleDesktopSideBar()
                toggleIcon()
              }}
            >
              <SvgIcon fontSize='small'>
                {hamIcon ? <MenuIcon /> : <MenuOpenIcon />}
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Hidden>

        <Hidden lgUp>
          <IconButton className={classes.hamburgerMenu} color='inherit' onClick={onMobileNavOpen}>
            <SvgIcon fontSize='small'>
              {hamIcon ? <MenuIcon /> : <MenuOpenIcon />}
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to={getHomePagePath()}>{renderLogo()}</RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {fileForUserValue.fileForUserLoginId && getFileForUser()}
        <Box ml={2} flexGrow={1} />
        {renderProductInfo && <ProductInfo />}
        {renderContactUs && <ContactUs />}
        {renderTradeResources && <TradeResources />}
        {renderFAQ && <FAQ />}
        {renderAboutUs && <AboutUs />}
        {shouldHideNotifications ? null : <Notifications />}
        {
          <CngSettings
            getThemeLibrary={getThemeLibrary}
            languages={[
              { key: 'english', value: 'en', label: 'English' },
              { key: 'french', value: 'fr', label: 'Français' },
              { key: 'spanish', value: 'es', label: 'Español' }
            ]}
          />
        }
        <Box ml={2}>
          <Accounts logoutRedirectUrl={logoutPath} />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

CustomTopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  onToggleDesktopSideBar: PropTypes.func,
  renderLogo: PropTypes.func,
  renderSettings: PropTypes.func,
  shouldHideNotifications: PropTypes.bool
}

export default CustomTopBar
